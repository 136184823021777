module.exports = [{
      plugin: require('/zeit/239a7179/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.js"},
    },{
      plugin: require('/zeit/239a7179/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('/zeit/239a7179/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/zeit/239a7179/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/zeit/239a7179/src/components/layout.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-prismjs","options":{"classPrefix":"language-"}},{"resolve":"gatsby-remark-copy-linked-files","options":{}},{"resolve":"gatsby-remark-images","options":{"maxWidth":590}},{"resolve":"gatsby-remark-code-repls","options":{"externals":["https://cdnjs.cloudflare.com/ajax/libs/react/16.2.0/umd/react.production.min.js","https://cdnjs.cloudflare.com/ajax/libs/react-dom/16.2.0/umd/react-dom.production.min.js"],"dependencies":["d3"],"codesandbox":["react","react-dom","d3"],"html":"<div id=\"root\"></div>","directory":"/zeit/239a7179/examples/","target":"_blank"}},{"resolve":"gatsby-remark-autolink-headers","options":{}}]},
    },{
      plugin: require('/zeit/239a7179/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
