import { graphql } from 'gatsby';
import { CodesandboxEmbed, InstagramEmbed, TweetEmbed, TypeformEmbed, VideoEmbed } from "../../../src/components/embeds";
import React from 'react';
import { MDXTag } from '@mdx-js/tag';
export default {
  graphql,
  CodesandboxEmbed,
  InstagramEmbed,
  TweetEmbed,
  TypeformEmbed,
  VideoEmbed,
  React,
  MDXTag
};